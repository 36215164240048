import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./Publications.css"
import Publication from "../publication/Publication";

const Publications = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [publications, setPublications] = useState([]);

//   const publicationExample = {
//     _id: 1,
//     title: "Título de la publicación",
//     year: "2023",
//     authors: [
//         { name: "Juan", lastName: "González Pérez" },
//         { name: "María", lastName: "Hernández García" },
//         { name: "Carlos", lastName: "Martínez Pérez" },
//         { name: "Ana", lastName: "Romero Martínez" },
//         { name: "Pedro", lastName: "Ruiz Sánchez" },
//         { name: "Luisa", lastName: "García Hernández" },
//         { name: "Pablo", lastName: "Pérez García" },
//         { name: "Sara", lastName: "Martínez Ruiz" },
//         { name: "Jorge", lastName: "García Pérez" },
//         { name: "Cristina", lastName: "Sánchez Martínez" }
//     ],
//     publisher: "Editorial Ejemplo",
//     url: "https://www.ejemplo.com/publicacion"
// };

  useEffect(() => {
    axios.get(backendURL + "/api/v1/publications/").then((response) => {
      setPublications(response.data);
    });
  }, [backendURL]);

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    // Función que se ejecuta cuando se hace scroll
    function handleScroll() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const currentScrollPos = window.pageYOffset;
        
        // Si la distancia al header es menor que la distancia al body, hacer scroll al header
        if (prevScrollPos > currentScrollPos) {
          headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        // Si la distancia al body es menor que la distancia al header, hacer scroll al body 
        else {
          bodyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        prevScrollPos = currentScrollPos;
      }, 100); // Ajusta este valor según sea necesario
    }

    // Agregar el evento de scroll al window
    window.addEventListener("scroll", handleScroll);

    // Retornar una función de limpieza para remover el evento de scroll cuando se desmonte el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="publications-page">
      <div ref={headerRef} className="publications-page-header">
        <h1>Publicaciones</h1>
        <p>Un poco de investigación.</p>
      </div>
      {publications.length > 0 && (
        <div ref={bodyRef} className="publications-page-body">
          {[...publications].reverse().map((publication) => (
            <Publication key={publication.id.toString()} publication={publication} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Publications;
