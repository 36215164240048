import React from "react";
import "./Idea.css";

const Idea = (props) => {
  const { idea, explanaition } = props.idea;

  return (
    <div className="idea">
        <div className="idea-details">
            <h3>{idea}</h3>
            <p>{explanaition}</p>
        </div>
    </div>
  );
};

export default Idea;
