import React from "react";
import "./Project.css";

const Project = (props) => {
  const { title, subtitle, startDate, endDate, status, image_url, content, url } = props.project;

  return (
    <>
      <hr />
      <div className="project">
        <div className="project-image">
          <img src={image_url} alt={title} />
        </div>
        <div className="project-details">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <p>{`Fecha de inicio: ${startDate} - Fecha de finalización: ${endDate}`}</p>
          <p>Estado: {status}</p>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <a href={url}>Enlace al proyecto</a>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Project;
