import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Projects.css"
import Project from "../project/Project";

function ProjectsPage() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [projects, setProjects] = useState([]);

  // const exampleProject = {
  //   _id: 1,
  //   title: "Proyecto 1",
  //   subtitle: "Subtítulo del proyecto 1",
  //   startDate: "2022-01-01",
  //   endDate: "2022-02-01",
  //   status: "En progreso",
  //   image_url: "https://m.gjcdn.net/game-screenshot/400/175967-t2cznyjq-v4.webp",
  //   content: "Descripción del proyecto 1",
  //   url: "https://gamejolt.com/games/apocalipzis/58215"
  // };

  useEffect(() => {
    axios.get(backendURL + "/api/v1/projects/").then((response) => {
      setProjects(response.data);
    });
  }, [backendURL]);

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    // Función que se ejecuta cuando se hace scroll
    function handleScroll() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const currentScrollPos = window.pageYOffset;
        
        // Si la distancia al header es menor que la distancia al body, hacer scroll al header
        if (prevScrollPos > currentScrollPos) {
          headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        // Si la distancia al body es menor que la distancia al header, hacer scroll al body 
        else {
          bodyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        prevScrollPos = currentScrollPos;
      }, 100); // Ajusta este valor según sea necesario
    }

    // Agregar el evento de scroll al window
    window.addEventListener("scroll", handleScroll);

    // Retornar una función de limpieza para remover el evento de scroll cuando se desmonte el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="projects-page">
      <div ref={headerRef} className="projects-page-header">
        <h1>Proyectos</h1>
        <p>En esta página encontrarás mis proyectos personales.</p>
      </div>
      {projects.length > 0 && (
        <div ref={bodyRef} className="projects-page-body">
          {[...projects].reverse().map((project) => (
            <Project key={project.id.toString()} project={project} />
          ))}
        </div>
      )}
    </div>
  );
}

export default ProjectsPage;
