import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./LandingPage.css";

function LandingPage() {
    return (
        <div className="landing-page">
            <div className="landing-page-header">
                <img src="/img/sebastian.png" alt="Foto de Sebastian Tobar Quintero" />
                <h1>¡Hola! Soy Sebastian Tobar Quintero</h1>
                <p>Desde que tenía doce años, he estado fascinado con las computadoras. Me emocionan los nuevos avances en la ciencia y la tecnología, y estoy siempre buscando nuevas formas de innovar y crear. No salgo mucho. Pero, ¿quién lo necesita cuando se tiene el poder de crear tus propios mundos?</p>
            </div>
            <div className="landing-page-footer">
                <div className="social-links">
                    <a href="https://co.linkedin.com/in/sebastq/es/?utm_source=sebastians_blog&utm_medium=linkedin&utm_campaign=sebastians_blog_lading_page" target="_blank" rel="noreferrer">
                        LinkedIn
                        {/* <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />  */}
                    </a>
                    <a href="https://github.com/sebastiantq-protonmail/?utm_source=sebastians_blog&utm_medium=github&utm_campaign=sebastians_blog_lading_page" target="_blank" rel="noreferrer">
                        GitHub
                        {/* <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />  */}
                    </a>
                    <a href="https://www.instagram.com/sebastq_/?utm_source=sebastians_blog&utm_medium=instagram&utm_campaign=sebastians_blog_lading_page" target="_blank" rel="noreferrer">
                        Instagram
                        {/* <i className="fab fa-instagram"></i> */}
                    </a>
                    <a href="https://x.com/sebastq_/?utm_source=sebastians_blog&utm_medium=twitter&utm_campaign=sebastians_blog_lading_page" target="_blank" rel="noreferrer">
                        X
                        {/* <i className="fab fa-twitter"></i> */}
                    </a>
                    {/* Agrega aquí más logos de redes sociales si lo deseas */}
                </div>
                <p>© 202X Sebastian. Todos los derechos reservados.</p>
                <p>¿Esto sirve de algo? 😂</p>
            </div>
        </div>
    );
}

export default LandingPage;
