import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Ideas.css";
import Idea from "../idea/Idea"

function Ideas() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [ideas, setIdeas] = useState([]);

  // const ideaExample = {
  //   _id: 1,
  //   idea: "esto es una idea",
  //   explanaition: "explicacion de la idea"
  // }

  useEffect(() => {
    axios.get(backendURL + "/api/v1/ideas/").then((response) => {
      setIdeas(response.data);
    });
  }, [backendURL]);

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    // Función que se ejecuta cuando se hace scroll
    function handleScroll() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const currentScrollPos = window.pageYOffset;
        
        // Si la distancia al header es menor que la distancia al body, hacer scroll al header
        if (prevScrollPos > currentScrollPos) {
          headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        // Si la distancia al body es menor que la distancia al header, hacer scroll al body 
        else {
          bodyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        prevScrollPos = currentScrollPos;
      }, 100); // Ajusta este valor según sea necesario
    }

    // Agregar el evento de scroll al window
    window.addEventListener("scroll", handleScroll);

    // Retornar una función de limpieza para remover el evento de scroll cuando se desmonte el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="ideas-page">
      <div ref={headerRef} className="ideas-page-header">
        <h1>Ideas</h1>
        <p>¿Cómo la encontraste?</p>
      </div>
      {ideas.length > 0 && (
        <ol ref={bodyRef} className="ideas-page-body">
          {[...ideas].reverse().map((idea) => (
            <li>
              <Idea key={idea.id.toString()} idea={idea} />
            </li>
          ))}
        </ol>
      )}
    </div>
  );
}

export default Ideas