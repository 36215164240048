import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Blog.css";
import Post from "../post/Post";
import MicroPost from "../microPost/MicroPost";

function Blog() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [posts, setPosts] = useState([]);
  const [isBloggingOpen, setIsBloggingOpen] = useState(true);
  const [isMicrobloggingOpen, setIsMicrobloggingOpen] = useState(true);

  // const postExample = {
  //   _id: 1,
  //   title: "Mi primer post",
  //   subtitle: "¡Bienvenidos a mi blog!",
  //   date: "2023-04-02",
  //   // imageUrl: "https://mi-sitio.com/imagen-post.jpg",
  //   image_url: undefined,
  //   content: "¡Hola a todos! Este es mi primer post en mi nuevo blog. Estoy muy emocionado de compartir mis pensamientos e ideas con ustedes. Espero que disfruten leyendo mis publicaciones tanto como yo disfruto escribiéndolas.",
  //   author: "Juan Perez"
  // }

  useEffect(() => {
    axios.get(backendURL + "/api/v1/blog/").then((response) => {
      setPosts(response.data);
    });
  }, [backendURL]);

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    function handleScroll() {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos > currentScrollPos) {
          headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          bodyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        prevScrollPos = currentScrollPos;
      }, 100);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bloggingPosts = posts.filter(post => 
    post.subtitle !== "" || 
    post.content.length > 333 ||
    post.image_url !== ""
  );

  const microbloggingPosts = posts.filter(post => 
    post.subtitle === "" &&
    post.content.length <= 333 &&
    post.image_url === ""
  );

  return (
    <div className="blog-page">
      <div ref={headerRef} className="blog-page-header">
        <h1>Blog</h1>
        <p>Comparto un poco de lo que se me pasa por la mente.</p>
      </div>
      <div className="blog-page-body">
        <div 
          className={`blog-section ${isBloggingOpen ? 'open' : ''}`} 
          ref={bodyRef}
        >
          <h2 onClick={() => setIsBloggingOpen(!isBloggingOpen)}>Blogging</h2>
          {isBloggingOpen && (
            <div>
              {[...bloggingPosts].reverse().map((post) => (
                <Post key={post.id.toString()} post={post} />
              ))}
            </div>
          )}
        </div>
        <div 
          className={`microblogging-section ${isMicrobloggingOpen ? 'open' : ''}`}
        >
          <h2 onClick={() => setIsMicrobloggingOpen(!isMicrobloggingOpen)}>Microblogging</h2>
          {isMicrobloggingOpen && (
            <div>
              {[...microbloggingPosts].reverse().map((post) => (
                <MicroPost key={post.id.toString()} microPost={post} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
