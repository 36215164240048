import React from "react";
import "./Post.css";

const Post = (props) => {
  const { title, subtitle, date, image_url, content, author } = props.post;

  return (
    <>
      <hr />
      <div className="post">
        {image_url && (
          <div className="post-image">
            <img src={image_url} alt={title} />
          </div>
        )}
        <div className="post-details">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <p>{`Fecha de publicación: ${date}`}</p>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          <p>{author}</p>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Post;
