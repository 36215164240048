import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

function Menu() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="menu-container">
      <button className="hamburger-menu-button" onClick={toggleMenu}>
        <span className="hamburger-menu-icon"></span>
      </button>
      <nav className={`menu ${showMenu ? 'show-menu' : ''}`}>
        <Link to="/" onClick={toggleMenu}>
          <button className="menu-button">Inicio</button>
        </Link>
        <Link to="/blog/" onClick={toggleMenu}>
          <button className="menu-button">Blog</button>
        </Link>
        <Link to="/projects/" onClick={toggleMenu}>
          <button className="menu-button">Proyectos</button>
        </Link>
        <Link to="/publications/" onClick={toggleMenu}>
          <button className="menu-button">Publicaciones</button>
        </Link>
        <Link to="https://www.basecomputing.com.co/?utm_source=sebastians_blog&utm_medium=web&utm_campaign=sebastians_blog_lading_page" target="_blank" onClick={toggleMenu}>
          <button className="base-computing-menu-button"><img src="/img/base-computing.png" alt="Base Computing S.A.S."/></button>
        </Link>
      </nav>
    </div>
  );
}

export default Menu;
