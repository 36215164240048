import React from "react";
import "./Publication.css";

const Publication = (props) => {
    const { title, year, authors, publisher, url } = props.publication;

    const formattedAuthors = authors.map((author, index) => {
        const { name, lastName } = author;
        let formattedName = `${name.charAt(0).toUpperCase()}.`;
        const formattedLastName = lastName.split(' ').map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');

        if (index === 3 && authors.length > 4) {
            return "et al."
        } else if (index > 2) {
            return null; // Ignorar autores adicionales
        }

        return `${formattedLastName}, ${formattedName}`;
    }).filter((author) => author !== null).join(" ");

    return (
        <>
            <hr />
            <div className="publication">
                <div className="publication-details">
                    <h2>{title}</h2>
                    <p>{formattedAuthors} ({year})</p>
                    <p>Disponible en <a href={url} target="_blank" rel="noopener noreferrer">{publisher}</a></p>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Publication;
