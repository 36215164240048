import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Menu from './components/menu/Menu';
import LandingPage from './components/landingPage/LandingPage';
import Blog from './components/blog/Blog';
import Projects from './components/projects/Projects';
import Publications from './components/publications/Publications';
import Ideas from './components/ideas/Ideas';
import NotFound from './components/notFound/NotFound';

import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route path='/' element={ <LandingPage/> }></Route>
        <Route path='/blog/' element={ <Blog/> }></Route>
        <Route path='/projects/' element={ <Projects/> }></Route>
        <Route path='/publications/' element={ <Publications/> }></Route>
        <Route path='/ideas/' element={ <Ideas/> }></Route>
        <Route path='*' element={ <NotFound/> }></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
