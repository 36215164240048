import React from "react";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="not-found">
      <h1>404 Not Found</h1>
      <p>Lo siento, la página que estás buscando no existe. ¿Qué intentas?</p>
      <a className="fixed-bottom" href="/">Volver al inicio</a>
    </div>
  );
}

export default NotFound;
